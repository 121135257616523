import '../../styles/pages/spring-summer-2021/blind-soprano-shoots-for-the-moon.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/spring-summer-2021-stories/more-stories"
import Vimeo from '@u-wave/react-vimeo';
import { CSSTransition } from 'react-transition-group';
import { Parallax } from 'react-parallax';
// import TextPDF from '../../components/spring-summer-2021-stories/text-pdf';
import { withPrefix } from "gatsby"
import Icon from "../../components/icons"

import socialBanner from "../../images/spring-summer-2021/social-banners/blind-soprano-shoots-for-the-moon.jpg";

import nightSky from "../../images/spring-summer-2021/blind-soprano-shoots-for-the-moon/night-sky.jpg";
import moon from "../../images/spring-summer-2021/blind-soprano-shoots-for-the-moon/moon.png";
import trees from "../../images/spring-summer-2021/blind-soprano-shoots-for-the-moon/trees.png";
import cristinaJonesTransparent from "../../images/spring-summer-2021/blind-soprano-shoots-for-the-moon/cristina-jones.png";

import cristinaJones from "../../images/spring-summer-2021/blind-soprano-shoots-for-the-moon/cristina-jones.jpg"
import jasonAlexanderCristinaJones from "../../images/spring-summer-2021/blind-soprano-shoots-for-the-moon/Jason-Alexander_Cristina-Jones.jpg"

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locked: true,
      paused: false
    };
    this.hideIntroSlate = this.hideIntroSlate.bind(this);
  }

  hideIntroSlate(event) {
    this.setState({locked: false});
    this.setState({paused: true});
  }


  render() {
    var pageTitle = '‘Blind Soprano’ Shoots for the Moon';
    var pageDesc = 'A golden-voiced alumna advocates for artists of all abilities.';
    var slug = 'blind-soprano-shoots-for-the-moon';

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-summer-2021">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={this.state.locked ? `${slug}${' locked'}` : slug } />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>

        <a href={ withPrefix('/pdf/spring-summer-2021/blind-soprano-shoots-for-the-moon.pdf') } id="pdf-text" target="_blank">
          <Icon name="pdf" alt="PDF Icon" />
          Download text alternative PDF
        </a>

        <CSSTransition
        in={this.state.locked}
        classNames={{
          enter: "animated",
          enterActive: "animated fadeOutUp",
          enterDone: "animated fadeOutUp",
          exit: "animated fadeOutUp",
          exitActive: "animated fadeOutUp",
          exitDone: "animated fadeOutUp",
        }}
        >
        <div className="blind-soprano-container-start-slate">
          <div className="start-slate-container">
            <Vimeo
              video="522098775"
              autoplay={true}
              responsive={true}
              showPortrait={false}
              showTitle={false}
              showByline={false}
              onEnd={this.hideIntroSlate}
              paused={this.state.paused}
              style={{ backgroundColor:'#000000' }}
            />
            <span className="title">{pageTitle}</span>
            <span className="sub-title">The last thing Cristina Jones '13 saw before she went completely blind was the moon.</span>
            <button className="read-story-button" onClick={this.hideIntroSlate}>Read Story</button>
          </div>
        </div>
        </CSSTransition>
        <div className="blind-soprano-container">

          <Parallax
          bgImage={nightSky}
          bgImageAlt="Night sky wuth clouds"
          strength={500}
          className="blind-soprano-hero animated fadeIn slower">

            <CSSTransition
            in={this.state.locked}
            classNames={{
              enter: "animated",
              enterActive: "animated fadeInUp slower",
              enterDone: "animated fadeInUp slower",
              exit: "animated fadeInUp slower",
              exitActive: "animated fadeInUp slower",
              exitDone: "animated fadeInUp slower",
            }}
            >
            <div className="hero-text">
              <h1><span className="title-top-half">‘Blind Soprano’</span> <span className="title-bottom-half">Shoots for the Moon</span></h1>
              <span className="sub-title">A golden-voiced alumna advocates for artists of all abilities.</span>
              <p className="byline">By Lynn Juliano / Photos and videos courtesy of Cristina Jones</p>
            </div>
            </CSSTransition>

            <CSSTransition
            in={this.state.locked}
            classNames={{
              enter: "animated",
              enterActive: "animated fadeInDown slower delay-1s",
              enterDone: "animated fadeInDown slower delay-1s",
              exit: "animated fadeInDown slower",
              exitActive: "animated fadeInDown slower delay-1s",
              exitDone: "animated fadeInDown slower delay-1s",
            }}
            >
              <img src={moon} alt="" className="title-element moon" alt="" />
            </CSSTransition>
            <CSSTransition
            in={this.state.locked}
            classNames={{
              enter: "animated",
              enterActive: "animated fadeInUp slower delay-1s",
              enterDone: "animated fadeInUp slower delay-1s",
              exit: "animated fadeInUp slower delay-1s",
              exitActive: "animated fadeInUp slower delay-1s",
              exitDone: "animated fadeInUp slower delay-1s",
            }}
            >
              <img src={trees} alt="" className="title-element trees" alt="" />
            </CSSTransition>
            <CSSTransition
            in={this.state.locked}
            classNames={{
              enter: "animated",
              enterActive: "animated fadeIn slower delay-1s",
              enterDone: "animated fadeIn slower delay-1s",
              exit: "animated fadeIn slower delay-1s",
              exitActive: "animated fadeIn slower delay-1s",
              exitDone: "animated fadeIn slower delay-1s",
            }}
            >
              <img src={cristinaJonesTransparent} className="title-element jones"  alt=""/>
            </CSSTransition>

            <div className="height" />
          </Parallax>

          {/* <section className="blind-soprano-hero">
            <div className="hero-text">
              <h1><span className="title-top-half">‘Blind Soprano’</span> <span className="title-bottom-half">Shoots for the Moon</span></h1>
              <span className="sub-title">A golden-voiced alumna advocates for artists of all abilities.</span>
              <p className="byline">By Lynn Juliano / Photos and videos courtesy of Cristina Jones</p>
            </div>
          </section> */}
          <article className="wrap small story-content">

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p><span className="intro-text">The last thing Cristina Jones saw</span> before she went completely blind was the moon.</p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>Though she’s braved medical procedures, childhood bullying and discrimination in the music industry, Jones is candid and funny while sharing her experiences as a blind musician — even joking about her “peepers.”</p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>“I view losing my sight as losing a friend,” she shares. “Well into total blindness now, I still remember with fondness those ol’ sighted days.”</p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>The Cal State Fullerton alumna recently moved to Washington, D.C., along with her guide dog, Bradford, to begin a new role as membership building coordinator for the National Federation of the Blind. She works to connect people who are blind with resources to help them achieve their life goals. </p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>Outside the organization, Jones records music for films and albums, teaches private music lessons, and lends her voice to a variety of advocacy projects.</p>
            </ScrollAnimation>

          </article>
          <div className="media-break music-notes-background music-notes-background1">
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <figure>
              <img src={cristinaJones} alt="Cristina Jones" />
              <figcaption>Blindness is no obstacle for alumna Cristina Jones, an opera singer and advocate for artists of all abilities.</figcaption>
            </figure>
            </ScrollAnimation>
          </div>
          <article className="wrap small story-content">

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <h2>Losing Her Vision</h2>
            <p>Born in the Philippines, Jones was diagnosed with retinopathy of prematurity at age 1, an eye disorder that can lead to blindness. To access better medical care and resources, her family moved to Southern California.</p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>“In a lot of countries, disability is seen as a shameful thing,” explains Jones, who experienced gradual vision loss. “My parents wanted to make sure that I had opportunities, and also that I wouldn’t be seen as a pariah.”</p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>As a child, Jones struggled having both a twin sister and a brother who were sighted. “I grappled a lot with the question, ‘Why can’t I do what they can do?’”</p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>After joining the Braille Institute in Anaheim at age 8, Jones began to meet more people with visual impairments. She joined a choir and discovered her passion for music. </p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>“I started to realize that being blind wasn’t such a bad thing or a big deal,” she says. “This is just a part of who I am, and I can still live a fully functioning and fulfilled life.”</p>
            </ScrollAnimation>

          </article>
          <div className="media-break">
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <blockquote>
              <p>“I’m so thankful for the healing power of music and the catharsis of being vulnerable,” she shares. “And I appreciate being able to do advocacy in a fun, accessible way.” </p>
              <cite>- Cristina Jones ’13</cite>
            </blockquote>
            </ScrollAnimation>
          </div>
          <article className="wrap small story-content">

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <h2>Stepping Into the Spotlight</h2>
            <p>During her senior year of high school, Jones ’13 (B.M. music-voice) auditioned with Cal State Fullerton’s School of Music and immediately was accepted.</p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>Through the program, Jones fell in love with opera music, studying alongside faculty members Robert Istad, Mark Salters, Linda Leyrer, Janet Smith, Marla Ladd and Patricia Prunty.</p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>“As a person with a disability in the arts, you understand you’re going to hear ‘no’ a lot because you’re considered a liability on the stage,” explains Jones. “But this group of professors was fantastic about saying, ‘Yes! You have the ability to do it. Let’s figure out how we’re going to make it possible.’”</p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>Jones credits the music faculty with ingenuity on the stage, using additional props to ensure her safety and working with the university’s Disability Support Services to acquire specific music sheets in Braille.</p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>“Cristina is an extremely talented and hardworking performer,” recalls Salters, a lecturer in music who specializes in opera. “She frequently is the first to learn her music and she is able to learn stage movement with little assistance.”</p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>“Not only were the faculty accommodating and encouraging, but they would throw challenging  and physically active roles at me. I think that was the thing that I most appreciated about Cal State Fullerton,” shares Jones. “Later in the professional world, people handled me with kid gloves.”</p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>During college, Jones was diagnosed with malignant acute angle-closure glaucoma, which led to the removal of her right eye. Unphased, Jones continued her studies and starred in such CSUF productions as the opera “Gianni Schicchi.”</p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>“Her senior recital was the most amazing performance I have ever heard in all of my years at CSUF,” says Leyrer, a lecturer in music who specializes in vocal instruction. “She transported the audience to a different place with her golden voice.”</p>
            </ScrollAnimation>

          </article>
          <div className="media-break music-notes-background music-notes-background2">
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <figure className="video">
              <Vimeo
                video="522100566"
                responsive={true}
                showPortrait={false}
                showTitle={false}
                showByline={false}
              />
              <figcaption>“Goodnight Moon” by Eric Whitacre - performed by Cristina Jones and Brenda Varda</figcaption>
            </figure>
            </ScrollAnimation>
          </div>
          <article className="wrap small story-content">

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <h2>Navigating a Career in the Arts</h2>
            <p>With encouragement from CSUF faculty members, Jones applied to graduate school at the Royal Academy of Music in London, where a second bout with acute angle-closure glaucoma put significant pressure on Jones’ left eye.</p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>After completing her Master of Arts in voice performance, Jones returned home to talk with her family and doctors about removing her remaining eye.</p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>“It was daunting to make that permanent move into blindness, but it was also the best thing for me: It’s been progress and upward mobility and discovery since then,” says Jones, who now giggles about her “fabulous pair of prosthetics.”</p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>She’s learned to embrace the nickname “The Blind Soprano,” while continuing to face discrimination in pursuit of her career goals.</p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>“All over my resume, I was going out of my way to talk about everything except the fact that I was blind because I didn’t want to be discriminated against before I even got an audition,” shares Jones. “When I finally started to realize that I myself was feeding into that ableism, I decided to just own the nickname.”</p>
            </ScrollAnimation>

          </article>
          <div className="media-break music-notes-background music-notes-background3">
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <figure>
              <img src={jasonAlexanderCristinaJones} alt="Jason Alexander and Cristina Jones" />
              <figcaption>Actor Jason Alexander and Cristina Jones chat during a recording session for the 2020 album “Imagine That!”</figcaption>
            </figure>
            </ScrollAnimation>
          </div>
          <article className="wrap small story-content">

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>Jones recently lent her voice to the album “Imagine That!” featuring the music of “Sesame Street” by Joe Raposo and Jeff Moss. The album raises money for such organizations as Guide Dogs of America and the Gavin R. Stevens Foundation for the blind, and was featured in The New York Times’ “7 Albums for Kids That Adults Will Want to Hear, Too.”</p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>Before the COVID-19 pandemic, Jones co-produced a live, interactive theater show called “Art in the Dark,” using blackout sunglasses and other props to take audiences on a journey of what it’s like to be blind.</p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>“I’m so thankful for the healing power of music and the catharsis of being vulnerable. And I appreciate being able to do advocacy in a fun, accessible way.”</p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>As with many musicians, the pandemic has slowed performance work. But when it’s safe again, Jones will gladly hit the stage with her guide dog and golden voice. <span className="end-mark" /></p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p className="learn-more">
              Support the College of the Arts<br />
              <a href="https://campaign.fullerton.edu/arts/" target="_blank" rel="noopener noreferrer">campaign.fullerton.edu/arts</a>
            </p>
            </ScrollAnimation>
  
          </article>
        </div>

        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 